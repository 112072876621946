
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {Navbar, Nav, NavItem } from 'reactstrap';
import { HamburgerSqueeze} from 'react-animated-burgers'
import Fade from 'react-reveal/Fade';

export default class TopBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
          isOpen: false,
          fade: false,
          width: 0, 
          height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

      }

      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

      toggleButton = () => {
        this.setState({
          isOpen: !this.state.isOpen,
          fade: true
        })
      }

      toggleButton1 = () => {
        this.setState({
          isOpen: false,
        })
      }

      headers = () =>{

        if(this.state.isOpen === true){
          this.setState({
            isOpen: false
          })
        }
        return(
            <Nav navbar className="ordinaryNavbar">
            <NavItem>
              <NavLink to="/projects"><button onClick={this.toggleButton1} className={"pageLink pageLinkMain"}><h3>Projects</h3></button></NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact"><button onClick={this.toggleButton1} className={"pageLink pageLinkMain"}><h3>Contact</h3></button></NavLink>
            </NavItem>
          
            </Nav>
        )
      }

    render() {
        return (
            <div className="navigation">
                <div>
                  <Navbar color="faded" light>

                    <NavLink to="/" className="mr-auto pageLink">  
                      <button onClick={this.toggleButton1} className={"pageLinkMain"}>Mads Birkebæk</button>
                     </NavLink>
                     {this.state.width < 800
                        ? <HamburgerSqueeze isActive={this.state.isOpen} toggleButton={this.toggleButton} barColor="white" />
                        : <this.headers></this.headers>
                      }

                    {this.state.isOpen  &&
                        <Fade top collapse when={this.state.isOpen}>    
                          <Nav navbar>
                            <Fade top>
                                <NavLink to="/"><button onClick={this.toggleButton1} className={"pageLink"}><h3>Home</h3></button></NavLink>
                            </Fade>
                            <Fade top delay={150}>
                                <NavLink to="/projects"><button onClick={this.toggleButton1} className={"pageLink"}><h3>Projects</h3></button></NavLink>
                            </Fade>
                            <Fade top delay={300}>
                                <NavLink to="/contact"><button onClick={this.toggleButton1} className={"pageLink"}><h3>Contact</h3></button></NavLink>
                            </Fade>

                          
                          </Nav>
                          
                      </Fade>
                    }
               
                  </Navbar>
                </div> 
          </div>
        )
    }
}