import '../Style/ProjectPage.scss';

import React, { Suspense, useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react'
import List from './List.js';
import Fade from 'react-reveal/Fade';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import TopBar from './TopBar';
import Projects from '../Views/Projects';



const config ={
  '25th Hour':{
    background:"#2A2F3C",
    headerColor: '#e7e3d4',
    textColor: '#e7e3d4',
    text:'Time management application',
    cursor:'110, 118, 138'
  },
  'Laina':{
    background:'#804C2B',
    headerColor: '#ffc6a3',
    textColor: '#ffc6a3',
    text: 'Shape-changing technologies',
    cursor:'204, 134, 90'

  },
  'Cube':{
    background:'#79806C',
    headerColor: '#D9FE8C',
    textColor: '#D9FE8C',
    text:'Task management tool',
    cursor:'200, 204, 192'

  },
  'PlantIT':{
    background:'#CFE1D0',
    headerColor: '#3C613E',
    textColor: '#3C613E',
    text:'Assistive technologies',
    cursor:'255, 255, 255'


  }
}


const ProjectPage = ({cursorColor}) => {


    let speed = 0

    let position = 0;
    let rounded = 0;
    let attractMode = false;
    let attractTo = 0;
    let objs = Array(4).fill({dist:0})  
    let group = useRef()
    let projectSelected = false
    const [active, setActive] = useState("");
    const [elRefs, setElRefs] = useState([]);
    const [scaleItem, setScale] = useState(false);
    const [openModal, setOpenModal] = useState(false)

    const [direction, setDirection] = useState();
    const [TempRenderOrder, setRenderOrder] = useState(0)

    useEffect(() => {

        if(config[active.name]){
            cursorColor(config[active.name].cursor)
        }
    }, [active]);



    useEffect(() => {
      if(active.renderOrder > TempRenderOrder ){
        setRenderOrder(active.renderOrder)
        setDirection('bottom')
  
      } else if (active.renderOrder < TempRenderOrder){
          setRenderOrder(active.renderOrder)
          setDirection('top')
      }
    }, [active]);
  


    useEffect(() => {

        document.getElementById('scroll').addEventListener(
            'wheel', (e) => {
                speed -= e.deltaY*0.0003
            }
        )
    })

    


 

  const raf = () => {

    position += speed;
    speed *= 0.5;   
    
    objs.forEach((o, i) => {
      o.dist = Math.min(Math.abs(position + i ), 1)
      o.dist = 1 - o.dist**2 
      let scale = 1 + 0.2 * o.dist;
        if(!scaleItem && elRefs.length === 4){
          if(elRefs[i].current){
            elRefs[i].current.position.y = i * (-1.2) - position * 1.2;
            elRefs[i].current.scale.set(scale, scale, scale);
            elRefs[i].current.material.uniforms.distanceFromCenter.value = o.dist;
          } 
        }     
    })

    //Change the value of -3 to add another value on scroll
    rounded = Math.min(Math.max(Math.round(position), -3), 0) 
    let diff = (rounded - position)


    if (attractMode) {
      window.cancelAnimationFrame(raf)
      position += -(position - attractTo) * 0.04;

    } else {
      position += Math.sign(diff)*Math.pow(Math.abs(diff), 1)*0.015
      window.requestAnimationFrame(raf);

      // block.style.transform = `translate(0, ${position * 100}px)`;
    }    
  }


  window.requestAnimationFrame(raf)


  function Header (){


    if(config[active.name]){
      return(
          <div className={"ProjectInfo"}>
              <div className={"headline"}>
                  <h1 style={{color: config[active.name] ? config[active.name].headerColor: 'black'}}>{active.name}</h1>
             </div>
               <div className={"infoText"}>
                    <p style={{color:config[active.name].textColor}}>{config[active.name].text}</p>
                    </div>

                <div className="buttonToProject" style={{background:config[active.name].headerColor}} onClick={()=> setOpenModal(true)}>
                    <a style={{color:config[active.name].background, margin:'auto'}} onClick={()=> setOpenModal(true)}>
                    Open project
                    </a>
                </div>
       </div>
  
      ) 
    } else return null
  }


  return (
    <div className="page" >
        <div className="ProjectsPage">
            <TopBar></TopBar>
            <div className="ProjectsContent">
                <div id={"scroll"} className={`view`} style={{position:'fixed', top: 0, right: 0, bottom: 0, left:0,height:'100%', background: config[active.name] ? config[active.name].background: 'black'}} >
                    <div  className={'n'}> 
                            <Header></Header>
                     </div>
                    <div id="container">
                        <List setRefs={(e) => setElRefs(e)} scaleItem={(e) => setScale(e)} groupRef={group} innerRef={elRefs} handleUpdate={(e) => (setActive(e))} handleClicks={()=>setOpenModal(true)}></List>
                    </div>
                </div>
            </div>
            <Projects color={ config[active.name]? config[active.name]: '#ffffff'} handleClick={() => setOpenModal(false)} open={openModal} component={active.renderOrder}></Projects>
        </div>
    </div > 
  );
}

export default ProjectPage;