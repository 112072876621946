import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/index.scss';
import './Style/Navigation.scss';
import './Style/Project.scss';
import './Style/About.scss';
import './Style/PageText.scss';
import './Style/Notfound.scss';
import './Style/Images.scss';
import './Style/arrow.scss';
import './Style/Contact.scss';
import ScrollToTop from './Components/ScrollToTop';


ReactDOM.render(
    <BrowserRouter >
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root')
);

registerServiceWorker();