import React, { Component, useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Mads from '../Images/Extra/portrait1.jpg';
import Block from '../Images/Extra/block.svg';
import Skills from '../Images/Extra/Graph.svg';
import Footer from '../Images/Extra/Footer.svg';
import pdf from '../Images/Extra/CV-Indesign.pdf';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Blob } from 'react-blob'
import TopBar from '../Components/TopBar';
import Prismic from 'prismic-javascript'
import {RichText } from 'prismic-reactjs'
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position';

	
const apiEndpoint = 'https://madsbirkebaekportolio.cdn.prismic.io/api/v2'
const accessToken = 'MC5YM3NkMnhVQUFDY0FnUC1C.O--_ve-_vVA8bO-_ve-_ve-_vTlW77-9IO-_vQsU77-977-977-9bO-_ve-_vUfvv73vv70fX13vv71t77-9ew' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

function Loaded() {
    const [doc, setDocData] = useState(null)
 
    useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'page')
      )
      if (response) {
        setDocData(response.results[0])

      }
    }
    fetchData()
    }, [])

    return doc	
}

function AboutData(props){
    const dataText = Loaded(props);


    const linkResolver = (dataText) => {
        // Pretty URLs for known types
        if (dataText.type === 'about') return `/${dataText.uid}`
        // Fallback for other types, in case new custom types get created
        return `/doc/${dataText.id}`     
    }

    return (

        <React.Fragment>{
              dataText ? (
                    <div className="AboutText">
                        <RichText render={dataText.data.body[0].primary.about_text} linkResolver={linkResolver} />
                    </div>
      
              ) : <div></div>
            }
        </React.Fragment>
    )  
}

function BackgroundBlob ({style, props}) {

    const Imgdata = Loaded(props);

    return(
        <React.Fragment>
            {
            Imgdata ? (

                <div className="portait"> 
                    <Fade  right delay={200} duration={1600} >   
                        <Blob 
                            size='50vh'
                            src= {Imgdata.data.body[0].items[0].me.url}
                            animationDuration="15s"

                            style={{
                                position: 'relative',
                                zIndex: -1,
                                top:'25%',
                                backgroundColor: '#FCD757',
                                color: 'white',
                                opacity: 1,
                                fontSize: '50vh',
                                ...style
                            }}
                            {...props}
                            
                        />    
                    </Fade>         
                </div> ) : <div></div>
            }        
        </React.Fragment>

    )
}


	
export default class Bio extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            width: 0, 
            size: "50vh",
            show: false,
            hover: false,
            xpos: 0,
            ypos: 0

        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);     
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
        this.myRef = React.createRef()  
    }


    scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop)   

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth});
    }


    componentDidMount() {

        this.props.cursorColor("252, 215, 87")
        this.scrollToMyRef();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({show: true})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(){

        if(this.state.width > 769 && this.state.size !== "50vh"){
            this.setState({
                size: "50vh"
            })

        }
        if(this.state.width < 769 && this.state.size !== "40vh"){
            this.setState({
                size: "40vh"
            })
        }
    }
   

    mouseOver = () => {
        this.setState({hover: true});
    }
    mouseOut() {
        this.setState({hover: false});
    }

    Name = () => {
    
        return (
                <ReactCursorPosition
                    activationInteractionMouse={INTERACTIONS.HOVER} //default
                    className= {"cursordetect"}
                    hoverDelayInMs={250} //default: 0
                    hoverOffDelayInMs={150} //default: 0
                    onActivationChanged={(isActive) => {
                        this.setState({
                            hover: isActive.isActive
                        })
                    }}
                    onPositionChanged={(position) => {
                        this.setState({
                            xpos: position.position.x,
                            ypos: position.position.y
                        })
                    }}
                    
                >
                    <span >Mads Birkebæk</span>    
                </ReactCursorPosition>
    
        );
      };
      
 

    render() {
         
        return (
            <div className="page">      
                <TopBar ref={this.myRef}></TopBar>                 

                <div className={"aboutPage"} >


                    <Zoom opposite duration={400} when={this.state.hover} ><img style={{left:`${this.state.xpos}px`, top:`${this.state.ypos}px`}} alt="ME" src={Mads}/></Zoom>    
                    <Fade delay={750} duration={1800} >

                    <div className="introText clip-text">
                            <h2  role='textbox' aria-multiline='true'>
                                    I'm <this.Name className={".link"}></this.Name>, born in Denmark, raised in Denmark and currently living in Denmark.
                            </h2>
                    </div>
                    </Fade>

                    <Fade delay={1000} duration={1800} >


                    <div className="block">
                        <img alt="Waves" src={Block}></img>
                    </div>

                    </Fade>

                    
                        <AboutData></AboutData>

                    <div className="SkillsHeader">
                        <h2>
                            Skills
                        </h2>
                    </div>

                    <div className="SkillsList">
                        <div className="List1">
                            <h3>UX</h3>
                            <p>Understanding and creating experience with a user-centric approach.</p>
                        </div>
                        <div className="List2">
                            <h3>Technology</h3>
                            <p>Using new emerging technologies to innovate.</p>
                        </div>
                        <div className="List3">
                            <h3>Engineering</h3>
                            <p>Transforming vision into a living and functioning product</p>
                        </div>
                    </div>

                    <div className="SkillsDesc">
                        <div className="SkillsText">
                            <p>
                                My profile can best described in the intersection of technology, UX and engineering.  
                                
                            </p>
                                <div className="Link">
                                    <NavLink to="/projects" style={{color:'#FCD757', fontSize:'initial'}}>SEE PROJECTS</NavLink>
                                </div> 
                                <div className="Link1">
                                    <a href = {pdf} target = "_blank" rel="noopener noreferrer" >SEE RESUME</a>
                                </div> 
                        </div>
                        <div className="SkillsImg">
                            <img alt="Skills" src={Skills}></img>
                        </div>
                    </div>

                    <div className="Footer">
                        <img alt="Footer" src={Footer}></img>
                    </div>

                    <div className="Footer1">
                        <h2>Any further questions?</h2>
                        <br/>
                        <h2>Please send me an <a href="mailto:birkebaek.mads@gmail.com">email.</a></h2>
                    </div>
                        
                </div>
                
            </div>
        );
    }
}

