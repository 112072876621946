import React, { Component } from 'react'
import * as THREE from 'three'

class Cube extends Component {

    componentDidMount() {

        const width = document.body.offsetWidth/1.3;
        const height = document.body.offsetHeight/1.3;
        const scene = new THREE.Scene()
        
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
        const renderer = new THREE.WebGLRenderer({ antialias: true,  alpha: true })
        const texture = new THREE.TextureLoader().load( this.props.image );
        const geometry = new THREE.BoxGeometry(1, 1, 1)
        const material = new THREE.MeshBasicMaterial({ map: texture })
        const cube = new THREE.Mesh(geometry, material)
        
        camera.position.z = 1.6
        scene.add(cube)
        renderer.setSize(width, height)

        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.material = material
        this.cube = cube

  
      window.addEventListener('resize', this.handleResize)
  
      this.mount.appendChild(this.renderer.domElement)
      this.start()
    }
  
    componentWillUnmount() {
      this.stop()
      this.mount.removeChild(this.renderer.domElement)
    }

    componentDidUpdate(){
        this.material.map = new THREE.TextureLoader().load( this.props.image);
    }
  
    handleResize = () => {
        const width = document.body.offsetWidth/1.3;
        const height = document.body.offsetHeight/1.3;
        this.renderer.setSize(width, height)
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
    }
  
    start = () => {
      if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate)
      }
    }
  
    stop = () => {
      cancelAnimationFrame(this.frameId)
    }

    

    loadModels = () => {
            
    }
  
    animate = () => {
      this.cube.rotation.x += 0.006
      this.cube.rotation.y += 0.006
  
      this.renderScene()
      this.frameId = window.requestAnimationFrame(this.animate)
    }
  
    renderScene = () => {
      this.renderer.render(this.scene, this.camera)
    }
  
    render() {
      return (
        <div
          className="vis"
          ref={mount => {
            this.mount = mount
          }}
        />
      )
    }
  }
export default Cube
