import React, { Component } from 'react';
/* import Bach1 from '../../Images/MATY/IMG_1040.JPG'
import Bach3 from  '../../Images/MATY/IMG_1698.JPG'
import Bach4 from  '../../Images/MATY/IMG_1712.JPG'
import Bach2 from  '../../Images/MATY/IMG_1785.JPG'
import Bach5 from  '../../Images/MATY/IMG_1822.JPG' */
import Bach6 from  '../../Images/MATY/IMG_1852.PNG'
import Cross from  '../../Images/Extra/cross.svg'


export class MATY extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                                <h1>My Advise To You</h1>
                                <img alt="close" onClick={this.props.closeModal} src={Cross}/>

                    </div>
                    <div className={"TopImage"}>
                                    <img style={{width:'100%'}} src={Bach6}  alt="TopImage" ></img>
                    </div>  
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                                MATY is an urban intervention project that aims at bridging the old and young generation. We thought it could be cool if they could learn a little bit from each other. 
                            </p>
                        </li>
                        <li></li>  
                        <li></li>  
                    </ul>
                    <div className="ProjectVideo">
                        <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/urban.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    
                </div>
            </div>

        );
    }
}


