import React, { Component } from 'react';  
import Logo from '../../Images/WorkingProject/Minimized-Mockup.png'; 
import Logo2 from '../../Images/WorkingProject/Logo.png';  
import Logo1 from '../../Images/WorkingProject/NotificationLogo.png';  
import Cross from  '../../Images/Extra/cross.svg'
import Home from '../../Images/WorkingProject/Home-01.png';  
import Overview from '../../Images/WorkingProject/Overview-01.png';  

import Phone from '../../Components/Phone';
import { CgClose } from 'react-icons/cg';
import LineUp from '../../Images/WorkingProject/Screens-02-02.png';  
import Fade from 'react-reveal/Fade';
import Wave from '../../Components/Wave';


export default class WorkingProject extends Component {

    constructor(props) {
        super(props)
    }



    render() {
        return (            
            <div className="page">
                <div className="PopupProject-Beige thHour" style={{background:this.props.color.background}}>    
                    <div className="ProjectHeader">
                        <h1 style={{color:this.props.color.textColor}}>25th Hour</h1>
                        <CgClose className={"close"} style={{color:this.props.color.textColor}} onClick={this.props.closeModal}  />

                    </div>
                    <div style={{background:'#2A2F3C'}}>

             
                   <Phone hasFaded={this.props.hasFaded} images={this.props.images}></Phone>
 

                    <ul className={"projectsText"}>
                        <li style={{margin:'5%'}}>
                            <h1  style={{color:'#DBB670'}} >THE PROBLEM</h1> 
                            <p style={{color:this.props.color.textColor}} >
                                Keeping track of your working hours when your workload is increasing can be a difficult. At the same time as it is important to get paid for the hours you put in and to keep track of your progress and activities.
                            </p>
                        </li>
                        <li style={{margin:'5%'}}>
                            <h1  style={{color:'#DBB670'}} >THE SOLUTION</h1> 
                            <p style={{color:this.props.color.textColor}} >
                            25th Hour is an application where it is possible to keep both track of working hours, activities and responsibilities at work in a simple and smooth way. Built in React Native and available at <a style={{color:'#DBB670'}} target = "_blank" href="https://play.google.com/store/apps/details?id=com.work_25thhour"> Google Play Store.</a>
                            </p>
                        </li>
                    </ul> 

            

          
                        <div className="ProjectContent">
                           <img src={LineUp}></img>
                           <Wave style={{position:'fixed'}} props={this.props.color.textColor}> </Wave>

                        </div> 


                        <ul className={"projectsText"} style={{marginTop:'50px'}}>
                            <li  style={{margin:'5%', alignSelf:'center'}}>
                                <h1 style={{color:'#DBB670'}}>TRACK YOUR HOURS</h1> 
                                <p style={{color:this.props.color.textColor}} >
                                On the home screen the working hours are displayed, both how many hours you are scheduled to worked and how much you have worked over a month. There is a shortcut to input the working hours of the current day.
                                </p>
                            </li>
                            <li > 
                                <img style={{margin:'auto', height:'100%', width:'70%'}} src={Home}></img>
                            </li>
                        </ul> 
                        <ul className={"projectsText"}>
                            <li>    
                                <img style={{margin:'auto', height:'100%', width:'70%'}} src={Overview}></img>
                            </li>
                            <li  style={{margin:'5%', alignSelf:'center'}}>
                            <h1  style={{color:'#DBB670'}}>SMOOTH TRACKING</h1> 
                                <p style={{color:this.props.color.textColor}} >
                                Everyday the app updates your working hour according to the preset schedule. If you worked over a specific day you can go back an edit it in the overview. You can edit and change both the schedueld and worked hours on the specific days.
                                </p>
                            </li>
                        </ul> 
                        <ul className={"projectsText"}>
                        <li  style={{margin:'5%', alignSelf:'center'}}>
                                <h1  style={{color:'#DBB670'}}>LOG YOUR PROGRESS AND RESPONSIBILITIES</h1> 
                                <p style={{color:this.props.color.textColor}} >
                                The working log can help you keeping track of your tasks and responsibilities. Either to follow your own progress or as base for salary negotiations.                            </p>
                            </li>
                            <li style={{margin:'auto'}}>
                                <img style={{margin:'auto', height:'100%', width:'70%'}} src={Home}></img>
                             </li>
                        </ul> 
           

                    </div>   

                </div>  
            </div>
        );
    }
}
