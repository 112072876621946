import React, {useEffect, useState } from 'react';
import {Route, Switch } from 'react-router-dom';
import Notfound from "./Views/Notfound";
import About from "./Views/About";
import Contact from "./Views/Contact";
import posed, { PoseGroup } from 'react-pose';
import ProjectPage from './Components/ProjectPage';
import 'custom-cursor-react/dist/index.css';
import AnimatedCursor from "react-animated-cursor"


const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 400, beforeChildren: true },
    exit: { opacity: 0 }
  });

  Object.defineProperty(window, 'getEmail', {
    get: function() {
      return 'Birkebaek.mads@gmail.com';
    }
  });


const App = ()  => {

    const [cursorColor, setCursorColor] = useState("252, 215, 87")

    useEffect(() => {
      console.log('%cHello world! Need more info? Run getEmail cmd.', 'color: white; font-size: xx-large');
    },[])


        return (

          
            <div className="App" >
               
              <AnimatedCursor
                innerSize={15}
                outerSize={15}
                color={cursorColor}
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={4}
              />
                <div className="Content">
                    <Route 
                        render={({ location }) => (
                            <PoseGroup>
                            <RouteContainer key={location.pathname}>
                              <Switch location={location}>
                                <Route exact path="/"  key="home"><About cursorColor={(e) => setCursorColor(e)}></About></Route>
                                <Route  path="/projects"key="projects"><ProjectPage cursorColor={(e) => setCursorColor(e)}></ProjectPage></Route>
                                <Route  path="/contact"  key="contact"><Contact cursorColor={(e) => setCursorColor(e)}></Contact></Route>
                                <Route path="*" component={Notfound} />
                              </Switch>
                            </RouteContainer>
                          </PoseGroup>
                     
                    )}
                  />
                </div>
               
            </div>
        );
    
}
export default App;