import React, { Component } from 'react';
import Bach1 from '../../Images/Bachelor/bachelor.JPG'
/* import Bach3 from '../../Images/Bachelor/app_1.PNG'
import Bach4 from '../../Images/Bachelor/IMG_0612.JPG'
import Bach5 from '../../Images/Bachelor/IMG_0627.JPG'
import Bach6 from '../../Images/Bachelor/IMG_0705.JPG'
import Bach7 from '../../Images/Bachelor/IMG_0751.JPG'
import Bach8 from '../../Images/Bachelor/plante_1.jpg'
import ProjectLinkBottom from '../../Components/ProjectLinkBottom';
import Fade from 'react-reveal/Fade';
 */
import Cross from  '../../Images/Extra/cross.svg'
import { CgClose } from 'react-icons/cg';
import Wave from '../../Components/Wave';

export class Bachelor extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige" style={{background:this.props.color.background}}>
                    <div className="ProjectHeader">
                        <h1 style={{color:this.props.color.textColor}}>PlantIT</h1>
                        <CgClose className={"close"} style={{color:this.props.color.textColor}} onClick={this.props.closeModal}  />

                    </div>
                    <div className={"TopImage"}>
                            <img style={{width:'100%'}} alt="FrontImage" src={Bach1}></img>
                    </div>  
                  
                    <ul className={"projectsText"}>
                        <li>
                            <p style={{color:this.props.color.textColor}}>
                                This is one of my most favourite projects. Together with residents and employees in a nursing home, we built an assistive sensor system to support monitoring the resident’s use of toilets in a way which both the residents and the employees could benefit from. To improve the feel of homeliness we built the system as an interactive plant for the elderly residents and an application for the employees.
                            </p>
                        </li>
                        <li></li>
                    </ul>  



                    <ul className={"projectsText ul"} >
                         
                         <li style={{color:this.props.color.textColor}}>
                             <p style={{color:this.props.color.textColor, fontWeight:'bold'}}>
                                 Technologies:   
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - Mircocontroller (Xbee)
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - Particle Photon
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - React JS webapp
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - 3D printing
                             </p>
                         </li>
                         <li></li>
                     </ul>  


                   
                    <div className="ProjectVideo">
                        <video playsInline width="75%" controls  style={{ position: 'absolute', top: '25%', left: '12.5%'}}  >
                            <source src="https://media.frederikkold.dk/plantit.mp4" type="video/mp4"/>
                        </video>
                        <Wave style={{position:'fixed'}} props={this.props.color.textColor}> </Wave>

                    </div>
                     
               </div>
            </div>
        );
    }
}

