import React, { Component } from 'react';
import Bach1 from '../../Images/SAINT/SAINT.jpg'
import Bach2 from '../../Images/SAINT/IMG_0579.JPG'
import Bach3 from '../../Images/SAINT/IMG_0615.JPG'
import Bach4 from '../../Images/SAINT/IMG_0637.JPG'
import Bach5 from '../../Images/SAINT/IMG_3856.PNG'
import Bach6 from '../../Images/SAINT/IMG_4714.JPG'
import Cross from  '../../Images/Extra/cross.svg'

export class SAINT extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                        <h1>Ding-Dong See My Son</h1>
                        <img alt="close" onClick={this.props.closeModal} src={Cross}/>

                    </div>
                    <div className={"TopImage"}>
                            <img style={{width:'100%'}} src={Bach1} alt="TopImage"  ></img>
                    </div>  
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                            I know, the name of the project needs an explanation. In the municipality of Aarhus, parents have the opportunity to ring the bell at the library from the hospital when they have born a new name. With this context under the bell, we displayed with a physical installation how much we might intrepet from big data, and thereby make people reflect upon the data they give about themselves and what consequences it might give your child in the future. Heavy stuff. 
                            </p>
                        </li>
                        <li></li>
                    </ul>  
                    <div className="ProjectVideo">
                    <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/saint.mp4" type="video/mp4"/>
                        </video>
                    </div>            
                </div>

            </div>

        );
    }
}

SAINT.defaultProps = {
    images: [
        {
            src: Bach1,
            thumbnail: Bach1,
            width: 1000
        },
        {
            src: Bach5,
            thumbnail: Bach5,
        },
        {
            src: Bach2,
            thumbnail: Bach2,
        },
        {
            src: Bach3,
            thumbnail: Bach3,
        },
        {
            src: Bach4,
            thumbnail: Bach4,
        },
        
        {
            src: Bach6,
            thumbnail: Bach6,
        },        
    ]
};
