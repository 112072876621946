import React, { Component } from 'react';
import Bach9 from '../../Images/Laina/Product.png'
import Bach10 from '../../Images/Laina/Mid-term-model1.png'
import Bach11 from '../../Images/Laina/Mid-term-model2.png'
import Bach12 from '../../Images/Laina/Mid-term-model3.png'
import Bach13 from '../../Images/Laina/Mid-term-model4.png'
import Scenario from '../../Images/Laina/Scenario_Tekengebied 1.png'
import Runners from '../../Images/Laina/Runners_Tegnebræt 1.png'

import ImageGallery from 'react-image-gallery';
import Cross from  '../../Images/Extra/cross.svg'
import { CgClose } from 'react-icons/cg';
import Wave from '../../Components/Wave';

const images = [
    {
      original: Bach12,
    },
    {
      original: Bach11,
    },
    {
      original:Bach13,
    },
    {
        original:Bach10,
    },
  ];
   

export class Laina extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }


    render() {
        return (
                <div className="page">
                    <div className="PopupProject-Beige" style={{background:this.props.color.background}}>
                        <div className="ProjectHeader">
                            <h1 style={{color:this.props.color.textColor}}>Laina</h1>
                            <CgClose className={"close"} style={{color:this.props.color.textColor}} onClick={this.props.closeModal}  />
                        </div>

                 
                        <div className={"TopImage"}>
                                <img style={{width:'100%'}} src={Bach9}  alt="TopImage" ></img>
                        </div>  
                       
                        <ul className={"projectsText"} >
                            <li>
                                <p style={{color:this.props.color.textColor}}>
                                    I did this project during my exchange in Eindhoven. The concept of Laina aims at exploring how slower reward mechanism can help female runners to be more positive towards running. The concept of Laina ended up as a shape-changing wall-art that changes shape based on the runs being made. It’s pretty cool, right?
                               </p>

                            </li> 
                            <li></li>
                        </ul>  

                        <ul className={"projectsText ul"} >
                         
                            <li style={{color:this.props.color.textColor}}>
                                <p style={{color:this.props.color.textColor, fontWeight:'bold'}}>
                                    Technologies:   
                                </p>
                                <p style={{color:this.props.color.textColor}}>
                                - Mircocontroller (ESP32-WROOM)
                                </p>
                                <p style={{color:this.props.color.textColor}}>
                                - React JS webpage (w. firebase)
                                </p>
                                <p style={{color:this.props.color.textColor}}>
                                - Lasercutting and 3D printing
                                </p>
                            </li>
                            <li></li>
                        </ul>  
                      

                       

                        <div className="ProjectVideo">
                        <video playsInline width="75%" controls  style={{ position: 'absolute', top: '25%', left: '12.5%'}}  >
                                <source src="https://media.frederikkold.dk/LAINA-VITALITY.mp4" type="video/mp4"/>
                            </video>
                            <Wave style={{position:'fixed'}} props={this.props.color.textColor}> </Wave>
                        </div>

                        <div className="ProjectContent">
                            <img src={Scenario}></img>
                        </div> 

                        <div className="ProjectContent">
                            <div className="ProjectHeader">
                                <h1 style={{color:this.props.color.textColor}}>Exploration</h1>
                            </div>
                           {/*  <ImageGallery 
                                    items={images} 
                                    infinite={true}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showBullets={false}
                                    showThumbnails={false}
                            />  */}
                            <div className="ProjectVideo">
                            <video playsInline width="75%" controls  style={{ position: 'absolute', top: '25%', left: '12.5%'}}  >
                                    <source src="https://media.frederikkold.dk/LAINA-midterm.mp4" type="video/mp4"/>
                                </video>
                                <Wave style={{position:'fixed'}} props={this.props.color.textColor}> </Wave>

                            </div>
                        </div> 
                        <div className="ProjectContent">
                            <img style={{width:'100%', marginBottom:'-150px'}} src={Runners}></img>
                        </div> 
                    </div>
                </div>
        );
    }
}

