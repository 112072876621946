import React, { Component } from 'react';
import Bach5 from '../../Images/Auxetta/IMG_0799.jpeg'
import Bach10 from '../../Images/Auxetta/Form1.png'
import Bach11 from  '../../Images/Auxetta/Form2.png'
import Bach12 from  '../../Images/Auxetta/Form3.png'
import Bach13 from  '../../Images/Auxetta/Form4.png'
import ImageGallery from 'react-image-gallery';
import Cross from  '../../Images/Extra/cross.svg'



const images = [
    {
      original: Bach12,
    },
    {
      original: Bach11,
    },
    {
      original:Bach13,
    },
    {
        original:Bach10,
    },
  ];
   

export class Auxetta extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                            <h1>Auxetta</h1>
                            <img  alt="close" onClick={this.props.closeModal} src={Cross}/>
                    </div>
                    <div className={"TopImage"}>
                        <img style={{width:'100%'}} src={Bach5}  alt="TopImage" ></img>
                    </div>  
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                                In collaboration with a danish elementary school, we created this very sleek shape-changing concept of Auxetta. Utilizing the shape-changing technologies for creating a scaled table we could accommodate different types of learning situations occurring in a typical elementary school lecture, while still taking advantage of the existing available space.
                            </p>
                        </li>
                        <li></li>
                        <li></li>
                    </ul>    
                    <div className="ProjectVideo">
                        <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/auxetta.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    <div className="ProjectContent">
                            <div className="ProjectHeader">
                                <h1>Constellations</h1>
                            </div>
                            <ImageGallery 
                                    items={images} 
                                    infinite={true}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showBullets={false}
                                    showThumbnails={false}
                            /> 
                        
                    </div> 
                
                </div>       
            </div>
        );
    }
}

