import React, { Component } from 'react';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
/* import Bach1 from '../../Images/RoundSound/IMG_0137.jpeg'
import Bach2 from '../../Images/RoundSound/IMG_0256.jpeg'
import Bach3 from '../../Images/RoundSound/IMG_0279.jpeg'
import Bach4 from '../../Images/RoundSound/IMG_0312.jpeg'
import Bach5 from '../../Images/RoundSound/IMG_0341.jpeg' */
import Bach6 from '../../Images/RoundSound/IMG_0347.jpeg'
import Cross from  '../../Images/Extra/cross.svg'


export class TheRoundSound extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {

        return (   
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                            <h1>RoundSound</h1>
                            <img alt="close" onClick={this.props.closeModal} src={Cross}/>
                    </div>
                    <div className={"TopImage"}>
                        <img style={{width:'100%'}} src={Bach6}  alt="TopImage" ></img>
                    </div>  
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                                The goal of this project was to create a music-installation for the danish music-festival NorthSide in Aarhus.
                                <br/>
                                <br/>
                                Through several iterations of brainstorming and bodystorming we created TheRoundSound. TheRoundSound builds on a idea where people in collaboration create and explore the music and thereby becomes a part of the music being played. TheRoundSound shape is inspired to have a reference of playing with ball as a metaphor with the intention of making people exploring the music through play.
                            </p>
                        </li>
                        <li> </li>   
                    </ul> 
                    <div className="ProjectVideo">
                        <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/round-sound.mp4" type="video/mp4"/>
                        </video>
                    </div>         
                </div>      
            </div>
        );
    }
}
