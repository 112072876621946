import React, { Component } from 'react';  
import Logo from '../../Images/MasterThesis/Aarhus_Logo.png';  
import Cross from  '../../Images/Extra/cross.svg'

export default class MasterThesis extends Component {

    render() {
        return (            
            <div className="page">
                <div className="PopupProject-Beige">    
                    <div className="ProjectHeader">
                            <h1>Master Thesis</h1>
                            <img  alt="close" onClick={this.props.closeModal} src={Cross}/>

                    </div>
                    <div className={"TopImage"} style={{width:'80%', margin:'auto'}}>
                                <img  style={{width:'100%', margin:'auto'}} alt="TopImage" src={Logo}></img>
                    </div>  
                           
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                            In my Master thesis, we did a collaboration with the innovation department of Borgerservice within the municipality of Aarhus. Here we investigated some of the important aspects when it comes to public participation and how information technologies can play a part in these. We found this not to be an very easy task within the municipality, and we, therefore, fully respect those people who work every day towards a better society in the future.
                            <br/>
                            <br/>
                            The result of the collaboration ended up in a platform which can be found at: <a  rel="noopener noreferrer"  href="https://omaarhus.dk" target="_blank" style={{color:'#black', fontSize:'initial'}}>Omaarhus.dk</a>
                            </p>
                        </li>
                        <li></li>
                    </ul>     
                </div>  
            </div>
        );
    }
}