import React, { Component } from 'react';
/* import Bach1 from '../../Images/PhysicalCom/IMG_0057.JPG';
import Bach2 from '../../Images/PhysicalCom/IMG_0062.JPG';
import Bach3 from '../../Images/PhysicalCom/IMG_0066.JPG';
import Bach4 from '../../Images/PhysicalCom/IMG_0070.JPG'; */
import DanDreamerPic from '../../Images/ProjectPictures/DanDreamer_IMG.jpg';
import Cross from  '../../Images/Extra/cross.svg'

export class PhysicalComputing extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                        <h1>DanDreamer</h1>
                        <img alt="close" onClick={this.props.closeModal} src={Cross}/>

                    </div>
                    <div className={"TopImage"}>
                            <img style={{width:'100%'}} alt="FrontImage" src={DanDreamerPic}></img>
                    </div> 

                    <ul className={"projectsText"}>
                        <li>
                            <p>
                                Sleep can be categorized into different stages such as REM and non-REM. Between these stages of the sleep a physiological change happens on the human body. Measurements can be done the those changes and make it possible to achieve a optimal waking time. 
                                <br/>
                                <br/>
                                Based upon that research we developed a concept for a wearable device. Our concept differs from some of the existing products is in the way we use two parameters to get closer to a standardization. With this knowledge we built a prototype to demonstrate and test this use case. To succeed in making the prototype we had ten different components, a built PCB and a 3D model to contain all the electronics. 

                            </p>
                        </li>
                        <li></li>
                    </ul> 
                    <div className="ProjectVideo">
                        <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/dandreamer.mp4" type="video/mp4"/>
                        </video>
                    </div>      
                </div>    
            </div>
        );
    }
}