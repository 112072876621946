import React, { Suspense, useRef, useState, useEffect, createRef } from 'react'
import { Canvas, useFrame, } from 'react-three-fiber'
import * as THREE from 'three';
import FragmentShader from '../Shaders/fragment.glsl'
import VertexShader from '../Shaders/vertex.glsl'

import First from '../Images/ProjectPictures/Laina_PIC.png'
import Second from '../Images/ProjectPictures/Logo1_Tegnebræt 1.png'
import Third from '../Images/ProjectPictures/CUBEs_IMG.jpg'
import Fourth from '../Images/ProjectPictures/PlantIT_IMG.jpg'
import gsap from "gsap";
import { TweenMax } from "gsap";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const vert = `
uniform float time;
varying vec2 vUv;
uniform vec2 pixels;
uniform vec2 uMeshScale;
uniform vec2 uMeshPosition;
uniform vec2 uViewSize;


float PI = 3.141592653589793238;
uniform float distanceFromCenter;
void main() {

  vUv = (uv - vec2(0.5))*(0.8 - 0.2*distanceFromCenter*(2. - distanceFromCenter)) + vec2(0.5);
  vec3 pos = position.xyz;
  pos.y += sin(PI*uv.x)*0.01;
  pos.z += sin(PI*uv.x)*0.02;
  
  pos.y += sin(time*0.3)*0.02;
  vUv.y -= sin(time*0.3)*0.02;


  gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
}
`

const frag = `
uniform float time;
uniform float progress;
uniform float distanceFromCenter;
uniform sampler2D texture1;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
float PI = 3.141592653589793238;
void main() {

  
  vec4 t = texture2D(texture1, vUv);
  float bw = (t.r + t.b + t.g)/3.;
  vec4 another = vec4(bw,bw,bw,1.);

  	

  gl_FragColor = mix(another,t,distanceFromCenter);
  gl_FragColor.a = clamp(distanceFromCenter,0.2,1.);
}
`


let groups = []
let materials = []
let meshes = []
let time = 0
let Images = [{img:Second, name:"25th Hour", val:1}, {img:First, name:"Laina", val:2}, {img:Third, name:"Cube", val:3}, {img:Fourth, name:"PlantIT", val:4}]


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const material = new THREE.ShaderMaterial({
  side: THREE.DoubleSide,
  extensions: {
    derivatives: "#extension GL_OES_standard_derivatives : enable",
  },
  vertexShader: vert,
  fragmentShader: frag,
  uniforms: {
    time: { type: "f", value: 0 },
    distanceFromCenter: { type: "f", value: 0 },
    texture1: { type: "t", value: null },
    resolution: { type: "v4", value: new THREE.Vector4() },
    uvRate1: { value: new THREE.Vector2(1, 1) },
    uProgress: new THREE.Uniform(0),
    uMeshScale: new THREE.Uniform(new THREE.Vector2(1, 1)),
    uMeshPosition: new THREE.Uniform(new THREE.Vector2(0, 0)),
    uViewSize: new THREE.Uniform(new THREE.Vector2(1, 1)),
    uColor: new THREE.Uniform(new THREE.Vector3(20, 20, 20))
  },
  // wireframe: true,
  transparent: true,
});

let firstuuid = ""


function List({handleUpdate, innerRef, setRefs, handleClicks}) {

  const [active, setActive] = useState("");
  const group = useRef(null)
  const camera = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    createMeshes()
    updateRef()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize());
  }, []);

    //On load animation 

  useEffect(() => {
      if(group.current){
      HandleAnimation()  
      
        if(firstuuid === ""){
          firstuuid = group.current.uuid
/*           HandleAnimation()  
 */        }
      }
  }, [group.current]);
  

  const handleResize = () =>{
    let width = window.innerWidth

    if(group.current){

      let ref = group.current.position

      wait(400).then(() => {
        if(width < 800){
          TweenMax.to(ref, 1.5, {
            x: 0.6,
            y: 0,
            z: -2
          })
        }
        if(width > 812){
          TweenMax.to(ref, 1.5, {
            x: 0.9,
            y: 0,
            z: 0
          })
        }
        if(width > 2000){
          TweenMax.to(ref, 1, {
            x: 0.9,
            y: 0,
            z: 1
          })
        }
      });
     
    }

  }

  const wait = (timeout) => {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  }

  const HandleAnimation = () =>{

    handleResize()
    wait(1400).then(() => {
      gsap.to(group.current.rotation, {
        duration: 0.6,
        x: -0.3,
        y: -0.7,
        z: -0.2,
      })
    });
  }

 
  function createMeshes () {


    meshes = []
    groups = []
    const loader = new THREE.TextureLoader();

    let group = new THREE.Group();

    Images.forEach((im, i) => {

        let mat = material.clone();
        materials.push(mat);

        const texture = loader.load(im.img);     
        texture.encoding = THREE.sRGBEncoding 

        mat.uniforms.texture1.value = texture 
        mat.uniforms.uViewSize.x = window.innerWidth;
		    mat.uniforms.uViewSize.y = window.innerHeight;

        let geo = new THREE.PlaneBufferGeometry(1.7, 1, 20, 20);
        let mesh = new THREE.Mesh(geo, mat);

        mesh.name = im.name
        mesh.renderOrder = im.val

        meshes.push(mesh)
        group.add(mesh);
        groups.push(group);
    })
  }

  function updateRef () {
    setRefs(innerRef => (
      Array(groups.length).fill().map((_, i) => innerRef[i] || createRef())
    ));
  }

  const HandleImages = () =>{

    const [scale, setScale] = useState(false);
    const [direction, setDirection] = useState('down');

    useEffect(() => {
      handleUpdate(active)
    } ,[active]);
  
    let mouse = new THREE.Vector2(0, 0)
    window.addEventListener('mousemove', (ev) => { onMouseMove(ev) })
    

    function onMouseMove(event) {
      TweenMax.to(mouse, 0.5, {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      })
    }

    useFrame(({mouse }) => {
      if(!scale){
        time += 0.05

        materials.forEach((m) => {
          m.uniforms.time.value = time;
        });

        innerRef.forEach((m) => {
          if(m.current.position.y > -0.6 && m.current.position.y < 0.6){
              if(m.current.name !== active && m.current.position.y !== undefined){
                TweenMax.to(m.current.rotation, 0.5, {
                  x: -mouse.y * 0.1,
                  y: mouse.x * (Math.PI / 20)
                })
                  if(m.current.name !== active.name){
                    setActive(m.current) 
                      
                  }
              }
          }
        });
      }     
    })  

    const handleClick = (e) => {
      if(e.object.name == active.name){

          innerRef.forEach((m) => {
          if(m.current.name == active.name){  
                 handleClicks()
 
            
               
/*             setScale(true)      

 */          }
        }) 
      }
    }



    return(
        <Suspense fallback={null}>
          <group 
          dispose={null}
          onPointerDown={(e) => (e.stopPropagation(), (handleClick(e)))}
          >
            {meshes.map((element, key) =>  
                <primitive ref={innerRef[key]} key={key}  object={element} />  
            )}
          </group>   
        </Suspense>
    )
  }


  return (
    <Canvas  gl={{ antialias: false}} pixelRatio={window.devicePixelRatio}  style={{height: '100%', width:'100%'}} camera={{ position: [0, 0, 7], fov: 20 }}>
        {/* This light makes things look pretty */}
        <perspectiveCamera ref={camera}></perspectiveCamera>
        <ambientLight intensity={1} />
        {/* Our main source of light, also casting our shadow */}
        <group ref={group} rotation={[0, 0, 0]} position={[0.9,-2,1]} >
          <HandleImages ></HandleImages>
        </group>
    </Canvas>
  );
}

export default List;