import React, {Component} from 'react'
import MATYPic from '../Images/ProjectPictures/MATY_IMG.png';
import AuxettaPic from '../Images/ProjectPictures/Auxetta_IMG.jpg';
import PlantITPic from '../Images/ProjectPictures/PlantIT_IMG.jpg';
import DanDreamerPic from '../Images/ProjectPictures/DanDreamer_IMG.jpg';
import CUBEsPic from '../Images/ProjectPictures/CUBEs_IMG.jpg';
import RoundSoundPic from '../Images/ProjectPictures/RoundSound_IMG.jpg';
import WorkingProjectPic from '../Images/ProjectPictures/Logo.png';
import * as THREE from 'three';
import OnPointPic from '../Images/ProjectPictures/OnPoint_PIC.jpeg';
import SaintPic from '../Images/ProjectPictures/SAINT.JPG';
import LainaPic from '../Images/ProjectPictures/Laina_PIC.png';
import MasterPic from '../Images/ProjectPictures/MasterThesis_PIC-01.png';  
import ProjectsPic from '../Images/ProjectPictures/Projects.png';  
import Fade from 'react-reveal/Fade';
import MasterThesis from './Projects/MasterThesis'
import { Laina } from './Projects/Laina';
import { CUBEs } from './Projects/CUBEs';
import { MATY } from './Projects/MATY';
import { Auxetta } from './Projects/Auxetta';
import { SAINT } from './Projects/SAINT';
import { Bachelor } from './Projects/Bachelor';
import { PhysicalComputing } from './Projects/PhysicalComputing';
import { OnPoint } from './Projects/OnPoint';
import { TheRoundSound } from './Projects/TheRoundSound';
import { Blob } from 'react-blob'
import TopBar from '../Components/TopBar';
import { InView } from 'react-intersection-observer';
import WorkingProject from './Projects/WorkingProject';
import ProjectPage from '../Components/ProjectPage';
import Screen1 from '../Images/WorkingProject/Screen1.png'
import Screen2 from '../Images/WorkingProject/Screen2.png'


let ImagesList = [Screen1, Screen2]

export default class Projects extends Component { 

    constructor(props) {
        super(props);
        this.state = { 
            hoverPicture: ProjectsPic,
            hasError: false,
            width: 0, 
            offset: -350,
            size: "80vh",
            counter: 0,
            open: props.open,
            component: 0,
            images: [],
            hasFaded: false
        };
        this.myRef = React.createRef()  
        this.updateLink = this.updateLink.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.node = React.createRef();       
    }

    openModal(param) {
        this.setState({ 
            open: true,
            component: param
        });
    }
    closeModal() {
        this.setState({ open: false });
        this.props.handleClick()
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            this.closeModal();
        } 
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth});
    }

    BackgroundBlob = ({style, props}) =>{
        return(
                
                <Blob 
                    size={this.state.size}
                    src= {this.state.hoverPicture}
                    animationDuration="13s"

                    style={{
                        position: 'relative',
                        zIndex: -1,
                        backgroundColor: 'rgb(25, 25, 25)',
                        color: 'white',
                        opacity: 1,
                        fontSize: '50vh',
                        ...style
                    }}
                    {...props}       
                />
        )
    } 

    setClass = () => {
        var x = document.getElementsByClassName("current");
        [].forEach.call(x, function(el) {
            console.log(el);

        });
    }

    updateImg = (element, name) => {

        if(element.isIntersecting === true){

            let x = document.getElementsByClassName("current");

            [].forEach.call(x, function(el) {
                el.classList.remove("current")
            });

            let param = element.target.textContent
 
            if(param === "Master Thesis"){

                this.setState({hoverPicture: MasterPic})            
                element.target.classList.add("current")

            }
            if(param === "Laina"){
                this.setState({hoverPicture: LainaPic})    
                element.target.classList.add("current")
        
            }
            if(param === "CUBEs"){
                this.setState({hoverPicture: CUBEsPic})  
                element.target.classList.add("current")
          
            }
            if(param === "MATY"){
                this.setState({hoverPicture: MATYPic})  
                element.target.classList.add("current")
          
            }
            if(param === "Auxetta"){
                this.setState({hoverPicture: AuxettaPic})   
                element.target.classList.add("current")
         
            }
            if(param === "Ding-Dong"){
                this.setState({hoverPicture: SaintPic})   
                element.target.classList.add("current")
         
            }
            if(param === "PlantIT"){
                this.setState({hoverPicture: PlantITPic})   
                element.target.classList.add("current")
         
            }
            if(param === "DanDreamer"){
                this.setState({hoverPicture: DanDreamerPic})   
                element.target.classList.add("current")
         
            }
            if(param === "OnPoint"){
                this.setState({hoverPicture: OnPointPic})   
                element.target.classList.add("current")
         
            }
            if(param === "RoundSound"){
                this.setState({hoverPicture: RoundSoundPic})
                element.target.classList.add("current")
            
            } 
            if(param === "25th Hour"){
                this.setState({hoverPicture: WorkingProjectPic})
                element.target.classList.add("current")
            
            } 

        }
    }
 

    handleClickOutside = (e) => {

        if(this.node.current === null){
            return
        }
        if(e.target.contains(this.node.current)){
            this.closeModal();
        };  
        if(JSON.stringify(e.target.className).includes("overlay-extra-content")){
            this.closeModal();
        }
    }

    handleComponent = () =>{
        if(this.props.component === 5){
            return <MasterThesis closeModal={this.closeModal} color={this.props.color}></MasterThesis>
        }
        if(this.props.component === 2){
            return <Laina closeModal={this.closeModal} color={this.props.color}></Laina>
        }
        if(this.props.component === 3){
            return <CUBEs closeModal={this.closeModal} color={this.props.color}></CUBEs>
        }
        if(this.props.component === 7){
            return <MATY closeModal={this.closeModal} color={this.props.color}></MATY>
        }
        if(this.props.component === 5){
            return <Auxetta closeModal={this.closeModal} color={this.props.color}></Auxetta>
        }
        if(this.props.component === 6){
            return <SAINT closeModal={this.closeModal} color={this.props.color}></SAINT>
        }
        if(this.props.component === 4){
            return <Bachelor closeModal={this.closeModal} color={this.props.color}></Bachelor>
        }
        if(this.props.component === 8){
            return <PhysicalComputing closeModal={this.closeModal}></PhysicalComputing>
        }
        if(this.props.component === 9){
            return <OnPoint closeModal={this.closeModal} color={this.props.color}></OnPoint>
        }
        if(this.props.component === 10){
            return <TheRoundSound closeModal={this.closeModal} color={this.props.color}></TheRoundSound>
        }
        if(this.props.component === 1){
            return <WorkingProject images={this.state.images} hasFaded={this.state.hasFaded} closeModal={this.closeModal} color={this.props.color} ></WorkingProject>
        }
    }


    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.closeModal();
        }
    }
    
    componentDidMount() {

        const loader = new THREE.TextureLoader();

        ImagesList.forEach(element => {

            const texture = loader.load(element);
            texture.encoding = THREE.sRGBEncoding
            const material = new THREE.MeshStandardMaterial({
            map: texture,
            color: null,
            alphaTest: 0.7,
            polygonOffset: true,
            polygonOffsetFactor: - 4,
            });  

            this.setState(prevState => ({
                images: [...prevState.images, material]
            }))

        });    

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener("keydown", this.escFunction, false);
        window.removeEventListener('resize', this.updateWindowDimensions);

    }
   
    updateLink = () => {

        var counter = 0
        var name = ""
        var x = document.getElementsByClassName("current");
        [].forEach.call(x, function(el) {
            name = el.innerText   
            counter += 1
        });
        if(counter === 1){
            counter = 0;
            this.updateImg(name);
        } 
    }

    componentDidUpdate(){

        if(this.state.width > 970 && this.state.size !== "80vh"){
            this.setState({
                size: "80vh",
                offset: -350,
            })
        } 
        if(this.state.width < 970 && this.state.size !== "60vh"){
            this.setState({
                size: "60vh",
                offset: -350

            })

        }
        if(this.state.width < 769 && this.state.size !== "60vh"){
            this.setState({
                size: "60vh",
                hoverPicture: null , 
                offset: -220
            })
        }
    } 

    resizePicture = () =>{
        var counters = 0
        if(counters === 0){
            this.setState({ counter: this.state.counter + 1 });
            counters += 1;
        }
    }



    list = () =>{

        return(     
            <ul>
    
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry, "section-2")}>

                    <li  id="section-2">
                        <button   to={'projects'} onMouseOver={() => this.setState({ hoverPicture: LainaPic}, this.resizePicture())} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(2)}>
                                <h3   className={"projectsNames"}>
                                        Laina 
                                </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li  id="section-11">
                        <button   to={'projects'} onMouseOver={() => this.setState({ hoverPicture: WorkingProjectPic}, this.resizePicture())} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(11)}>
                                <h3   className={"projectsNames"}>
                                        25th Hour 
                                </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>
                    <li  id="section-1" >
                        <button  onMouseOver={() =>this.setState({ hoverPicture: MasterPic })}  onMouseOut={() => this.updateLink()}  to={'projects'} onClick={() =>this.openModal(1)} >
                                <h3   className={"projectsNames"}>
                                            Master Thesis 
                                </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4}  rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-3" ref={this.myRef}>
                        <button     to={'projects'} onMouseOver={() => this.setState({ hoverPicture: CUBEsPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(3) }>
                            <h3  className={"projectsNames"}>
                                    CUBEs
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>
                    <li id="section-7">

                        <button to={'projects'} onMouseOver={() => this.setState({ hoverPicture: PlantITPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(7)} >
                            <h3 className={"projectsNames"}>                    
                                    PlantIT                 
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-4">
                        <button  to={'projects'} onMouseOver={() => this.setState({ hoverPicture: MATYPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(4) } >
                            <h3 className={"projectsNames"}>
                                    MATY
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-5">

                        <button to={'projects'}  onMouseOver={() => this.setState({ hoverPicture: AuxettaPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(5)} >
                            <h3 className={"projectsNames"}>
                                    Auxetta
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-6">

                        <button to={'projects'}onMouseOver={() => this.setState({ hoverPicture: SaintPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(6)}>
                            <h3 className={"projectsNames"}>
                                Ding-Dong
                            </h3>
                        </button>
                    </li>
                </InView>
              
                <InView as="div"  threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-8">
                        <button to={'projects'}  onMouseOver={() => this.setState({ hoverPicture: DanDreamerPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(8)} >
                            <h3 className={"projectsNames"}>
                                    DanDreamer
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-9">
                        <button to={'projects'} onMouseOver={() => this.setState({ hoverPicture: OnPointPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(9)} >
                            <h3 className={"projectsNames"}>
                                    OnPoint
                            </h3>
                        </button>
                    </li>
                </InView>
                <InView as="div" threshold={0.4} rootMargin={"-30%"} onChange={(inView, entry) => this.updateImg(entry)}>

                    <li id="section-10">
                        <button to={'projects'} onMouseOver={() => this.setState({ hoverPicture: RoundSoundPic })} onMouseOut={() => this.updateLink()}  onClick={() =>this.openModal(10)}>
                            <h3 className={"projectsNames"}>
                                    RoundSound
                            </h3>
                        </button>
                    </li>
                </InView>

            </ul>
        )
    }


    render() {    
        if(this.props.open){
            return (    
                <Fade delay={0} duration={100}>
                <div ref={this.node}  onScroll={this.handleScroll} className="ProjectModal">
                    <Fade wait={0} onReveal={() =>this.setState({hasFaded:true})} delay={900} bottom opposite><this.handleComponent /></Fade>
                    <div className="overlay-extra-content"></div>
                </div>               
                </Fade>
            );
        } else return null  
    }
}

Projects.displayName = 'Projects';

