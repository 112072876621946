import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import TopBar from '../Components/TopBar';
import Detail from '../Images/Extra/Detail.svg';


export default class Contact extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {

        this.props.cursorColor("252, 215, 87")
      
    }

    render() {
        return (
            <div className="page">
                <TopBar ref={this.myRef}></TopBar>
                <div className="Contact" >
                    <Fade delay={500} duration={1500}>    

                        <div className="introText">
                                <h2>
                                    Contact Me
                                </h2>

                        </div>
                    </Fade>
             
                    <Fade delay={500} duration={1700}>    
                        <div className="detail">
                            <img alt="Detail" src={Detail}></img>
                        </div>
                    </Fade>
                    <Fade delay={600}  duration={1700}>    
                                    <h1  >
                                        <p style={{wordBreak: 'break-all'}}>
                                        <br/>    

                                        Mads Birkebæk 
                                        <br/>      
                                        <br/>      
                                        +4553525024 / <a target="_blank" id="email"  rel="noopener noreferrer"  href="mailto:birkebaek.mads@gmail.com">birkebaek.mads@gmail.com</a>
                                        <br/>                                        
                                        <br/>      
                                        Aarhus, Denmark
                                        </p>
                                                          
                                    </h1>
                                </Fade>           
                        </div>
            </div>
        );
    }
}

