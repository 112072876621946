import React, { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas, useFrame} from 'react-three-fiber'
import {useGLTF} from '@react-three/drei/core/useGLTF'
import {softShadows} from '@react-three/drei/core/softShadows'

import {proxy} from 'valtio'
import * as THREE from 'three';
import { TweenMax } from "gsap";

import { useSpring, a } from "react-spring/three";
import Screen1 from '../Images/WorkingProject/Screen1.png'
import Screen2 from '../Images/WorkingProject/Screen2.png'


softShadows();

const state = proxy({
    current:'null',
    items:{
      phone: 'red'
    }
})


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function Box({position, rotation, material}) {

  const group = useRef()
  const group1 = useRef()

  const { nodes, materials } = useGLTF('/phone1.glb')
  const [expand, setExpand] = useState(false);
  const [materialToSet, setMaterialToSet] = useState(null);
  const [rotate, setRotate] = useState(false);
  const [initialScale, setInitialScale] = useState([0.01,0.01, 0.01]);


  const props = useSpring({
    scale: expand ? [0.12,0.12, 0.12] : initialScale,
    rotation : rotate ? [rotation[0] ,rotation[1], ((Math.PI *2)+rotation[2])] : [rotation[0], rotation[1], rotation[2]],
    onRest: () => {
      setRotate(false)
    }
  });


  useEffect(() => {

    setExpand(false)
    setMaterialToSet(null)
    setRotate(false)
    setMaterialToSet(material)
    setInitialScale([0.1,0.1, 0.1])

  } ,[]);



  if(rotate){
    group.current.rotation.set(Math.PI / 2, 0, 0)
  }

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.x = Math.cos(t / 4) / 5
    group.current.rotation.y = Math.sin(t / 4) / 5
    group.current.position.y = (1 + Math.sin(t / 1.5)) / 5    
  })  

  if(materialToSet){
    return (
      <group ref={group}>
      <a.group
        castShadow
        ref={group1}
        name="iPhone_12_Pro_Max"
        onPointerDown={(e) => (e.stopPropagation(), (setRotate(true)))}
        onPointerOver={(e) => (e.stopPropagation(), setExpand(true))}
        onPointerOut={(e) => e.intersections.length === 0 && setExpand(false)}
        position={position}
        rotation={rotate ? props.rotation : rotation}
        scale={props.scale}>
        <mesh castShadow material={materials.Body_Blue} geometry={nodes.iPhone_12_Pro_Max001.geometry} />
        <mesh castShadow material={materials.Antenna_Blue} geometry={nodes.iPhone_12_Pro_Max001_1.geometry} />
        <mesh castShadow material={materials.Black} geometry={nodes.iPhone_12_Pro_Max001_2.geometry} />
        <mesh castShadow material={materials.Glass_matte} geometry={nodes.iPhone_12_Pro_Max001_3.geometry} />
        <mesh castShadow material={materials.Glass} geometry={nodes.iPhone_12_Pro_Max001_4.geometry} />
        <mesh castShadow material={materials.Front} geometry={nodes.iPhone_12_Pro_Max001_5.geometry} />
        <mesh castShadow material={materials.Blue_back} geometry={nodes.iPhone_12_Pro_Max001_6.geometry} />
        <mesh castShadow material={materials.Steel_black} geometry={nodes.iPhone_12_Pro_Max001_7.geometry} />
        <mesh castShadow material={materialToSet ? materialToSet : materials} geometry={nodes.iPhone_12_Pro_Max001_8.geometry} />
        <mesh castShadow material={materials.Plastik_cam} geometry={nodes.iPhone_12_Pro_Max001_9.geometry} />
        <mesh castShadow material={materials.Glass_cam} geometry={nodes.iPhone_12_Pro_Max001_10.geometry} />
        <mesh castShadow material={materials.Light_yellow} geometry={nodes.iPhone_12_Pro_Max001_11.geometry} />
        <mesh castShadow material={materials.Light_orange} geometry={nodes.iPhone_12_Pro_Max001_12.geometry} />
        <mesh castShadow material={materials.Rubber_black} geometry={nodes.iPhone_12_Pro_Max001_13.geometry} />
        <mesh castShadow material={materials.Plastik_black} geometry={nodes.iPhone_12_Pro_Max001_14.geometry} />
        <mesh castShadow material={materials.Glass_flash} geometry={nodes.iPhone_12_Pro_Max001_15.geometry} />
        <mesh castShadow material={materials.Gold} geometry={nodes.iPhone_12_Pro_Max001_16.geometry} />
        <mesh castShadow material={materials['Glass.001']} geometry={nodes.iPhone_12_Pro_Max001_17.geometry} />
      </a.group>
    </group>
    )
  } else return null
  

}

function Phone(props) {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    

    return () => {
      window.removeEventListener('resize', handleResize)
    };

  }, []);


  useEffect(() => {
    setMounted(props.hasFaded)
  }, [props]);


  return (
    <div style={{height:'100%', background:'#2A2F3C', width:'100%'}}>

    <Canvas gl={{ antialias: true}} pixelRatio={window.devicePixelRatio/1.5} shadowMap  style={{height: windowDimensions.width < 768 ? (windowDimensions.width)/1.5 : (windowDimensions.width)/2, width:'100%'}} camera={{ position: [0, 0, 75], fov: 20 }}>
    <ambientLight intensity={1} />
    <directionalLight
          penumbra={1}
          castShadow
          position={[0, 15, 0]}
          intensity={5}
          shadow-mapSize-width={2000}
          shadow-mapSize-height={2000}
          shadow-camera-far={50}
          shadow-camera-left={-40}
          shadow-camera-right={40}
          shadow-camera-top={20}
          shadow-camera-bottom={-20}
        />
      <pointLight position={[0, 15, 10]} intensity={0.5} />
      {mounted && 
       <group>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -9, 0]}
          receiveShadow
          >
          <planeBufferGeometry attach='geometry' args={[200, 500]} />
          <shadowMaterial attach='material' opacity={0.4} />
        </mesh>
        <Suspense fallback={null}>
            {props.images &&
            <>     
            <Box screen={Screen1} material={props.images[0]} position={[7, -7, 0]} rotation={[Math.PI / 2, 0, 0.2]} ></Box>
            <Box screen={Screen2} material={props.images[1]} position={[-7, -7, 0]} rotation={[Math.PI / 2, 0, -0.2]}></Box> 
            </>
            }
        </Suspense>
        </group> 
      }
     
{/*  <OrbitControls />
 */}  </Canvas>
  </div>

  );
}

export default Phone;
