
import React from 'react';

const Wave = (props) => {

  return (
    
    <svg id="Lag_1" data-name="Lag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 922.07 699.46" style={{fill: props.props, width:'100%', height:'100%'}}>
        <path className="cls-1" d="M0,90C49.83,36.52,243.92-48.63,367.12,35.33c51.37,35,146.47-4.19,168.66-9.67,57.66-14.23,293.32-40.6,386.29,43.48V691.93c-29.66,6.9-104.78,13.33-166.3-1.34C679.35,672.36,617.44,648,527,660.06c-53.22,7.09-279.87,71.15-365.94,13.38C32,586.81,0,682.34,0,551.39Z"/>

    </svg>
    )
}

export default Wave;