import React, { Component } from 'react';  
import Bach1 from '../../Images/ITPDP/IMG_2620_2.jpeg'
import Bach2 from '../../Images/ITPDP/IMG_2143.jpeg'
import Bach3 from '../../Images/ITPDP/IMG_2388.jpeg'
import Bach4 from '../../Images/ITPDP/IMG_2395.jpeg'
import Bach5 from '../../Images/ITPDP/IMG_2459.jpeg'
import Cross from  '../../Images/Extra/cross.svg'

import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: Bach2,
    },
    {
        original: Bach3,
    },
    {
        original:Bach4,
    },
    {
        original:Bach5,
    },
  ];
   

export class OnPoint extends Component {

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige">
                    <div className="ProjectHeader">
                            <h1>OnPoint</h1>
                            <img alt="close" onClick={this.props.closeModal} src={Cross}/>

                    </div>
                    <div className={"TopImage"}>
                        <img style={{width:'100%'}} src={Bach1}  alt="TopImage" ></img>
                    </div>  
                    <ul className={"projectsText"}>
                        <li>
                            <p>
                                OnPoint is a product targeting elite volleyball players. OnPoint is designed and constructed to help the players to improve the execution of a pass in a drill through direct visual feedback. Furthermore the results can be reviewed using the integrated app.
                                <br/>
                                <br/>
                                OnPoint is based on an existing volleyball drill in which a hitter, a defender and a setter play the ball circularly between them. Our system focuses on the defender as the defender carries out the pass. The  quality of a pass is determined by two factors. One of the factors is how precise  the ball reaches the position where the sette  is standing. The second factor is the curve of the ball. A high curve gives the setter the best condition to carry out the set, and the defender obtains the best pass. The  defender receives feedback which expresses the quality of the pass.
                                <br/>
                                <br/>
                                The full proces is available right <a target="_blank"  rel="noopener noreferrer"  href="http://itpdp.frederikkold.dk/" style={{color:'#FCD757', fontSize:'initial'}}>here</a>
                            </p>
                        </li>
                        <li></li>               
                    </ul>  
                    <div className="ProjectVideo">
                    <video playsInline width="100%" height="100%" controls>
                            <source src="https://media.frederikkold.dk/onpoint.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    <div className="ProjectContent" style={{marginTop:"50px"}}>
                            <ImageGallery 
                                    items={images} 
                                    infinite={true}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showBullets={false}
                                    showThumbnails={false}
                            /> 
                    </div> 
                </div>
            </div>

        );
    }
}