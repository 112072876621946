import React, { Component } from 'react';
import Bach1 from '../../Images/Cube/Front.jpeg'
import switchLane1 from '../../Images/Cube/SwitchLane1.png'
import switchLane2 from '../../Images/Cube/SwitchLane2.png'

import Stack1 from '../../Images/Cube/Stack1.png'
import Stack2 from '../../Images/Cube/Stack2.png'

/* import Bach2 from '../../Images/Cube/Impl.jpeg'
import Bach3 from '../../Images/Cube/Task1.jpg'
 */
import Cross from  '../../Images/Extra/cross.svg'
import { CgClose } from 'react-icons/cg';
import Wave from '../../Components/Wave';


export class CUBEs extends Component {

    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render() {
        return (
            <div className="page">
                <div className="PopupProject-Beige" style={{background:this.props.color.background}}>
                    <div className="ProjectHeader">
                            <h1 style={{color:this.props.color.textColor}}>CUBEs</h1>
                            <CgClose className={"close"} style={{color:this.props.color.textColor}} onClick={this.props.closeModal}  />                    
                    </div>
                    <div className={"TopImage"}>
                                <img style={{width:'100%'}} src={Bach1}  alt="TopImage" ></img>
                    </div> 
                    <ul className={"projectsText"}>
                        <li>
                            <p style={{color:this.props.color.textColor}}>
                                CUBES is a project based on some of our own experiences working with online working tools such as Trello. We rather prefer working together in a physical space together as a group than in a digital space where you can easily hide behind the screen. Therefore, we built these kick-ass wireless tangible cubes making it much more fun and exciting to handle all the online working tasks. 
                            </p>
                        </li>
                        <li></li>
                    </ul>   


                    <ul className={"projectsText ul"} >
                         
                         <li style={{color:this.props.color.textColor}}>
                             <p style={{color:this.props.color.textColor, fontWeight:'bold'}}>
                                 Technologies:   
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - Mircocontroller (Wemos D1 Mini)
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             -  MQTT broker (network communication)
                             </p>
                             <p style={{color:this.props.color.textColor}}>
                             - Lasercutting and 3D printing
                             </p>
                         </li>
                         <li></li>
                     </ul>  

                  
                    <div className="ProjectVideo">
                        <video playsInline width="75%" controls  style={{ position: 'absolute', top: '25%', left: '12.5%'}}  >
                            <source src="https://media.frederikkold.dk/cubes.mp4" type="video/mp4"/>
                        </video>
                        <Wave style={{position:'fixed'}} props={this.props.color.textColor}>
                    
                        </Wave>
                    </div> 

                    <div className="ProjectContent">
                            <div className="ProjectHeader">
                                <h1 style={{color:this.props.color.textColor}}>Tasks</h1>
                            </div>
                            <ul className={"projectsText ul"} >
                                <li>
                                    <h1 style={{color:this.props.color.textColor, width:'90%', margin:'auto', marginBottom:'50px'}}>Merge tasks</h1 >
                                    <img src={Stack1}></img>

                                </li> 
                                <li>
                                <h1 style={{color:this.props.color.textColor, width:'90%', margin:'auto', marginBottom:'50px'}}>Share tasks</h1 >
                                    <img src={Stack2}></img>
                                </li>
                            </ul>  

                            <ul className={"projectsText ul"} >
                                <li>
                                <h1 style={{color:this.props.color.textColor, width:'90%', margin:'auto', marginBottom:'50px'}}>Switch lane (TO DO)</h1 >
                                    <img src={switchLane1}></img>
                                </li> 
                                <li>
                                    <h1 style={{color:this.props.color.textColor, width:'90%', margin:'auto', marginBottom:'50px'}}>Switch lane (IN PROGRESS)</h1 >

                                    <img src={switchLane2}></img>
                                </li>
                            </ul>  

                        </div> 
                </div>
            </div>
        );
    }
}

