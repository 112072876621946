import React, { Component } from 'react';
import TopBar from '../Components/TopBar';
import Cube from '../Components/Cube';
import Woops from '../Images/Extra/Logo_Mads1.png'

export default class Notfound extends Component {

    render() {
        return (
            <div className="page">
                <TopBar></TopBar>
                <div className="Notfound">
                         <Cube image={Woops}></Cube>                           
                </div>

            </div>
            
        );
    }
}

